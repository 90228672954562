import axios from 'axios';

export default class ConstantsService {
  constructor() { }

  static async getAllServiceTypes() {
    let serviceProviderTypes = [];
    try {
      let response = await axios.get('/api/all-service-types');
      for (const serviceType of response.data) {
        serviceProviderTypes.push({ id: serviceType.name, name: serviceType.name });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
    return serviceProviderTypes;
  }

  static async getAllBreeds() {
    let breeds = [];
    try {
      let response = await axios.get('/api/all-breeds');
      for (const breed of response.data) {
        breeds.push({ id: breed.name, name: breed.name });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
    return breeds;
  }

  static async getAllColors() {
    let colors = [];
    try {
      let response = await axios.get('/api/all-colors');
      for (const color of response.data) {
        colors.push({ id: color.name, name: color.name });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
    return colors;
  }

  static async getAllSexes() {
    let sexes = [];
    try {
      let response = await axios.get('/api/all-sexes');
      for (const sex of response.data) {
        sexes.push({ id: sex.name, name: sex.name });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
    return sexes;
  }

  static async getAllDisciplines() {
    let disciplines = [];
    try {
      let response = await axios.get('/api/all-disciplines');
      for (const discipline of response.data) {
        disciplines.push({ id: discipline.name, name: discipline.name });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
    return disciplines;
  }
}
